#more-info {
    padding: 50px;
    background-color: var(--primary);
    color: var(--black);
    text-align: center;
}

#more-info h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

#more-info p {
    font-size: 1.25rem;
    line-height: 1.5;
}
