.navbar {
  background: var(--white);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
  filter: drop-shadow(0 6px 20px lightgrey);
}
.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
}
.navbar-logo {
  color: var(--black);
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}
.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}
.nav-menu {
  display: grid;
  grid-template-columns: repeat(10, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 1rem;
  margin-left: 20rem;
}
.nav-item {
  height: 30px;
}
.nav-links {
  color: var(--black);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}
.nav-links.active {
  border-bottom: 4px solid var(--primary);
}
.nav-links:hover {
  border-bottom: 4px solid var(--primary);
  transition: all 0.2s ease-out;
}
.fa-bars {
  color: var(--black) !important;
}
.nav-links-mobile {
  display: none;
}
.menu-icon {
  display: none;
}

.my--button {
  background: var(--primary);
  color: var(--black);
}

@media screen and (max-width: 960px) {
  .navbar {
    position: sticky;
  }

  .nav-menu {
    display: none; /* Completely hide the menu by default */
    flex-direction: column;
    align-items: center; /* Horizontally center links */
    justify-content: space-evenly; /* Evenly distribute links vertically */
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -320px !important;
    background: var(--white);
    opacity: 0;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    display: flex; /* Show the menu when active */
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    margin: 0; /* Remove any extra vertical margin */
    padding: 0.5rem 1rem 2.5rem; /* the 2.5rem moves the active underline up and down*/
    width: 100%; /* Full-width links */
    display: block; /* Display links as blocks */
    font-size: 2rem; /* Increase font size for mobile menu links */
  }

  .nav-links:hover {
    color: #242424;
    border-radius: 0;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-bars,
  .fa-times {
    color: var(--black);
    font-size: 2rem;
  }

  .navbar-logo {
    position: absolute;
    top: 10px;
    left: 10px;
    transform: translate(0, 50%);
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto; /* Add spacing around the button */
    border-radius: 4px;
    width: 90%; /* Make the button wide */
    text-decoration: none;
    font-size: 1.8rem; /* Increase font size for the button */
    background-color: transparent;
    color: var(--primary);
    padding: 16px 24px; /* Increase padding for a bigger button */
    border: 2px solid var(--primary); /* Thicker border for emphasis */
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: var(--primary);
    color: var(--black);
    transition: 250ms;
  }

  /* Ensure the button in the nav-links list is styled similarly */
  .my--button {
    font-size: 3rem !important; /* Matches the nav-links-mobile */

  }

  .my--button:hover {
    background: var(--primary);
    color: var(--black);
  }

  
}
