/* Base styles for the video */
video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the video covers the container */
    z-index: -1; /* Keeps it in the background */
}

/* Styles for the hero container */
.hero-container {
    position: relative; /* Required for proper layering */
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    justify-content: center; /* Center items vertically */
    align-items: center; /* Center items horizontally */
    padding: 0 5%; /* Adds padding to the sides */
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)); /* Adjust transparency for a softer shadow */
    overflow: hidden; /* Prevents content overflow */
}

/* Content styles */
.cta-content {
    display: flex;
    flex-direction: column;
    max-width: 50%;
    color: var(--darkRed);
    align-items: center; /* Center content horizontally */
    text-align: center; /* Center text alignment */
}

.cta-content h1 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 2rem;
}

.cta-content p {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
    line-height: 1.5;
}

/* Image styles */
.image {
    width: 300px; /* Updated for larger image */
    height: 300px;
    border-radius: 50%;
    z-index: 1;
    margin: 20px auto; /* Center the image */
}

.white-button {
    background-color: var(--white); /* White background */
    color: var(--darkRed); /* Dark red text */
    border: 2px solid #5B242A; /* Dark red border */
    transition: all 0.3s ease; /* Smooth transition for hover */
  }
  
  .white-button:hover {
    background-color: #E2D6C9; /* Slightly darker white/cream hover effect */
    color: #242424; /* Change text color on hover */
  }
  

/* Media Queries for responsiveness */
@media screen and (max-width: 960px) {
    .hero-container {
        flex-direction: column; /* Stack elements vertically */
        padding: 9%;
    }

    .cta-content {
        max-width: 100%;
    }

    .cta-content h1 {
        font-size: 2.5rem;
    }

    .cta-content p {
        font-size: 1rem;
    }

    .image {
        margin: 0 auto 20px; /* Center the image and add spacing */
    }
}
