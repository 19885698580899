/* Button.css */
:root {
  --primary: #b9a082;
  --primaryOG: #E2D6C9;
  --darkRed: black;
  --black: #000000;
  --white: #ffffff;
}

.btn {
  padding: 8px 20px;
  border-radius: 150px; /* Applies to all buttons for rounded edges */
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--primary {
  background-color: #5B242A;
  color: var(--primary);
  border: 1px solid var(--primary);
  text-decoration: none;
}

.btn--outline {
  background-color: transparent;
  color: var(--black);
  padding: 8px 20px;
  border: 1px solid var(--black);
  transition: all 0.3s ease-out;
  text-decoration: none;
}

.btn--download {
  background-color: var(--darkRed);
  color: var(--white);
  border: 1px solid var(--darkRed);
  text-decoration: none;
}

.btn--credential {
  background-color: var(--primary);
  color: var(--darkRed);
  border: 1px solid var(--primary);
  text-decoration: none;
}

.btn--credential:hover {
  background-color: #cac0b5 !important; /* Slightly darker than --primary */
  transition: all 0.3 ease-out;
}

.btn--outline-inverse {
  background-color: var(--primary); /* Primary background */
  color: var(--darkRed); /* Dark red text */
  padding: 8px 20px;
  border: 1px solid var(--darkRed); /* Dark red outline */
  transition: all 0.3s ease-out;
  text-decoration: none;
}

.btn--outline-inverse:hover {
  background-color: var(--darkRed) !important; /* Transparent background on hover */
  color: var(--primary) !important; /* Primary color text */
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 20px;
  background-color: var(--black);
  color: var(--white);
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--medium:hover,
.btn--large:hover {
  background: var(--white);
  color: var(--black);
  transition: all 0.3s ease-out;
}
