* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: Arial, Helvetica, sans-serif; */
  
}

body {
  overflow-x: hidden;
}

.home,
.resume,
.projects,
.experience
.contact {
  display: flex;
  height: 70vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  min-height: 70vh;
}

.resume {
  background-image: url('/public/images/beigeBlobs-transformed.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: var(--darkRed);
  font-size: 100px;
  image-rendering: crisp-edges;
}

.projects {
  background-image: url('/public/images/beigeBlobs4-transformed.jpeg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: var(--darkRed);
  font-size: 100px;
  image-rendering: crisp-edges;
}

.experience {
  background-image: url('/public/images/waves.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: var(--darkRed);
  font-size: 100px;
  padding: 261px 0; 
}

.contact {
  background-image: url('/public/images/swirls.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: var(--darkRed);
  font-size: 100px;
  padding: 261px 0; 
}