.cards {
  padding: 3rem;
  background: var(--white); /* new color */
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.cards__items {
  margin-bottom: 24px;
  display: flex; /* Flex for wrapping */
  flex-wrap: wrap;
  justify-content: center; /* Center cards */
}

.cards__item {
  display: flex;
  flex: 1;
  margin: 1rem; /* Add spacing around cards */
  border-radius: 10px;
  width: 100%; /* Full width by default */
  max-width: 650px; /* Prevent cards from getting too large */
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px grey !important;
  -webkit-filter: drop-shadow(0 6px 20px grey);
  filter: drop-shadow(0 6px 20px grey);
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 0px !important;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 18px;
  font-weight: 700;
  color: var(--white) !important;
  background-color: var(--black) !important;
  box-sizing: border-box;
}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img:hover {
  transform: scale(1.1);
}

.cards__item__info {
  padding: 20px 30px 30px;
}

.cards__item__text {
  color: var(--black) !important;
  background-color: var(--primary);
  font-size: 18px;
  font-weight: 100 !important;
  line-height: 24px;
  padding: 10px;
  margin-top: -50px;
  text-align: center;
}

/* Large screens (>= 1024px) */
@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }

  .cards__item {
    width: 48%; /* Two cards per row */
    margin: 1rem; /* Add spacing between cards */
  }
}

/* Mobile screens (< 768px) */
@media only screen and (max-width: 767px) {
  .cards__item {
    width: 100% !important; /* Expand the card width closer to full width */
    margin: 1rem auto !important; /* Center the card with proper spacing */
    max-width: none !important; /* Remove any restrictive max-width */
    flex: none !important; /* Ensure cards do not shrink or grow unexpectedly */
  }

  .cards__item__text {
    font-size: 16px !important; /* Maintain readable font size for mobile */
    padding: 8px !important; /* Compact padding */
  }

  .cards__item__pic-wrap::after {
    font-size: 16px !important; /* Adjust label font size */
    padding: 4px 6px !important; /* Adjust padding */
  }
}

/* Adjust parent container to ensure cards have full available space */
.cards__items {
  justify-content: center !important; /* Ensure cards are centered */
  gap: 1rem !important; /* Add spacing between rows */
}

.cards__container {
  width: 100% !important; /* Ensure the container stretches to full width */
  padding: 0 !important; /* Remove any padding that could constrain the width */
}
