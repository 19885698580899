.GitHub-Button {
    cursor: pointer;
    background-color: var(--black);
    color: var(--white);
    border: 1px #cac0b5;
    height: 20px;
}

.GitHub-Button:hover {
    background-color: var(--white); /* a bit darker than --primary*/
    color: var(--black);
    transition: background-color 0.3s ease, color 0.3s ease;
}