.form-box {
    margin: auto;
    margin-top: 3%;
    padding: 7%;
    background-image: url('/public/images/swirl.png');
    background-size: cover;
    box-shadow: 0 6px 20px grey;

}
  
input, textarea {
    width: 50%;
}

.form-group {
    margin-bottom: 1rem;
    margin-top: 1%;
}

.contact-message {
    color: var(--darkRed);
    max-width: 30%;
    text-align: center;
    position: absolute;
    right: 8rem; /* Adjust the value according to your layout */
    transform: translateY(-50%);
    padding: 1rem;
    border-radius: 8px;
    padding-top: 10%;
}

@media (max-width: 768px) {
    .contact-message {
        position: static;
        transform: none;
        max-width: 100%;
        margin-bottom: 1rem;
        background: none; /* Remove background for mobile view */
        padding: 0; /* Remove padding for mobile view */
    }

    input, textarea {
        width: 100%;
    }
}