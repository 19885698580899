body {
    margin: 0;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    background-color: var(--white);
}

* {
    box-sizing: border-box;
}

.work {
    background-color: var(--primary);
    color: var(--darkRed);
    padding-bottom: 2% !important;
    padding-top: 1%;
}

.awards-title {
    color: var(--black);
}

.credentials {
    background-color: var(--white);
    color: var(--black);
    padding-top: 1px; /* Add space inside the div at the top */
    padding-bottom: 20px; /* Add space inside the div at the bottom */
    margin-top: 30px; /* Adds space above the div */
    margin-bottom: 30px; /* Adds space below the div */
}


.courses {
    background-color: var(--primary);
    color: var(--darkRed);
    margin-bottom: 2%;
    margin-top: 1%;
}

.text-container {
    margin: 0 20px;
}

.circle-list {
    list-style-type: circle;
    margin-left: 20px; /* Adjust the margin as needed */
}

.experience-item {
    margin-bottom: 20px; /* Adjust the margin between items as needed */
}

.education-title {
    margin-top: 20px; /* Add space above the role title */
}

.edu_date {
    font-size: 1.2rem;
    color: var(--darkRed);
    float: right;
}

@media only screen and (max-width: 1000px) {
    .education-title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .edu_date {
        float: none;
    }
}

/* Mobile screens (< 768px) */
@media only screen and (max-width: 767px) {
    .experience {
        font-size: 4.1rem !important;
    }

    .Study_Abroad_Title {
        display: flex;
        flex-direction: column; /* Stack the content vertically */
        align-items: flex-start; /* Align content to the left */
    }

    .small_date {
        margin-top: 5px; /* Add some space above the date */
        font-size: 1rem; /* Adjust font size for mobile */
        color: var(--darkRed); /* Keep consistent styling */
    }

    .work {
        padding-bottom: 4% !important;
    }
}
