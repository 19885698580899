.footer-container {
    background-color: var(--primary);
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    filter: drop-shadow(0 6px 20px lightgrey);
}
.footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
}
.footer-link-wrapper {
    display: flex;
}
.footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
}
.footer-link-items h2 {
    margin-bottom: 16px;
}
.footer-link-items > h2 {
    color: var(--black);
}
.footer-link-items a {
    color: var(--black);
    text-decoration: none;
    margin-bottom: 8px;
}
.footer-link-items a:hover {
    color: var(--black);
    transition: 0.3s ease-out;
}
/* social icons */
.social-icon-link {
    color: var(--black);
    font-size: 24px;
}
.social-media {
    max-width: 1000px;
    width: 100%;
}
.social-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;
}
.social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
}
.social-logo {
    color: var(--black);
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-self: center;
    margin-bottom: 16px;
}
.website-rights {
    color: var(--black);
    margin-bottom: 16px;
}
@media screen and (max-width: 820px) {
    .footer-links {
      padding-top: 2rem;
    }
  
    .footer-input {
      width: 100%;
    }
  
    .footer-link-wrapper {
      flex-direction: column;
    }

    .footer-link-items {
        align-items: center; /* Center individual items */
        text-align: center; /* Center the text */
        margin: 16px 30px; /* Adjust spacing between items */
        width: auto; /* Allow flexible width */
    }
  
    .social-media-wrap {
      flex-direction: column;
    }
  }
