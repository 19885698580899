.contact-items-container {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap to the next line */
    justify-content: space-around; /* Adjust this as needed to space items */
    margin-top: 20px; /* Adjust margin as needed */
}

.contact-item {
    width: 45%; /* Adjust width as needed */
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    margin: 10px;
    width: 25rem;
}

.contact-item img {
    max-width: 100px;
}

.contact-item h1 {
    font-size: 24px;
    margin-bottom: 10px;
}

.contact-item p {
    font-size: 16px;
    color: #333;
    margin-bottom: 20px;
}

.contact-item .btn--primary {
    margin-top: 10px;
}

/* QR Code positioning */
.QR {
    position: absolute;
    right: 20px; /* Align to the right side */
    height: auto;
    width: 150px; /* Adjust to a suitable size */
    margin-top: -1%;
}

@media (max-width: 768px) {
    .contact-item {
        width: 100%; /* Full width on smaller screens */
    }
}

/* Mobile screens (< 768px) */
@media only screen and (max-width: 767px) {
    .contact {
        font-size: 5.1rem !important;
    }
    .contact-item {
        width: 100%; /* Full width on smaller screens */
    }
}