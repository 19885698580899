/* Adjust the slideshow container width for mobile */
.slideshow-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.slide {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Adjust slide image size */
.slide-image {
    width: 100%;
    max-height: 300px;
    object-fit: contain;
    border-radius: 10px;
}

/* Slide description */
.slide-description {
    width: 100%;
    margin-top: 15px;
}

/* Text container overflow */
.text-container {
    overflow: visible;
}

/* Role title styling */
.role-title {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center; /* Center text */
}

/* Date styling */
.date {
    font-size: 1.2rem;
    color: var(--darkRed);
    text-align: center; /* Center text */
}

.small_date {
    float: right;
}

.awardDate {
    font-size: 1.2rem;
    color: var(--black);
    text-align: center; /* Center text */

}

/* Progress bar container */
.prep101-progress-bar {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    gap: 8px; /* Add spacing between buttons */
}

.description {
    text-align: center;
}

/* Customize the arrows */
.slick-prev:before,
.slick-next:before {
  color: var(--black) !important; /* Set the desired color */
  font-size: 24px; /* Adjust size if needed */
}

/* Media queries for mobile view */
@media (max-width: 768px) {
    .slideshow-container {
        max-width: 95%; /* Make slideshow narrower on mobile */
    }

    .slide-image {
        max-height: 200px; /* Reduce image height for mobile */
    }

    .role-title {
        font-size: 1.2rem; /* Smaller font for mobile */
    }

    .date {
        font-size: 1rem; /* Smaller font for mobile */
    }

    .prep101-progress-bar .btn {
        font-size: 0.8rem; /* Reduce button font size */
        padding: 6px 15px; /* Smaller button padding */
        text-align: center;
    }
}
